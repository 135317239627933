<template>
  <v-dialog max-width="900" width="100%" :value="true" scrollable>
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar color="error" class="mb-2">
          <v-btn icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-btn>
          <v-toolbar-title>Typ: {{ type.description }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="back" icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <RowItem align="center" title="Titel">
          <v-text-field v-model="type.title" />
        </RowItem>
        <RowItem align="center" title="verantwortlich">
          <PersonInput group="employee" clearable v-model="type.creator" />
        </RowItem>
        <RowItem label="text">
          <v-textarea
            outlined
            v-model="type.text"
            :hint="'Mögliche Platzhalter: {{comment}}, {{student}}, {{division}}, {{mayear}} und {{male|female}}'"
            persistent-hint
          />
        </RowItem>
        <RowItem title="Abteilung" align="center">
          <LookupValueInput
            single-line
            v-model="type.division"
            :items="divisions"
            clearable
          />
        </RowItem>
        <RowItem title="Stufe" align="center">
          <LookupValueInput
            single-line
            v-model="type.grade"
            :items="grades"
            clearable
          />
        </RowItem>
        <RowItem title="Semester" align="center">
          <LookupValueInput
            single-line
            v-model="type.semester"
            :items="semesters"
            clearable
          />
        </RowItem>
        <RowItem title="Fach" align="center">
          <LookupValueInput
            single-line
            v-model="type.subject"
            :items="subjects"
            clearable
          />
        </RowItem>
        <RowItem title="automatisch">
          <v-simple-checkbox v-model="type.autogenerate" />
        </RowItem>
        <RowItem title="versteckt">
          <v-simple-checkbox v-model="type.hidden" />
        </RowItem>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" text @click="save">Speichern</v-btn>
        <v-btn text @click="close">schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import RowItem from "@/components/RowItem.vue";

export default {
  name: "PortfolioTypeEdit",
  props: ["id"],
  components: { LookupValueInput, PersonInput, RowItem },
  data() {
    return {
      divisions: [],
      grades: [],
      semesters: [],
      subjects: [],
      type: {},
      loading: false,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: "PortfolioTypeList",
      });
    },

    async save() {
      await this.apiPut({
        resource: "portfolio/type",
        data: this.type,
      });
      this.$root.showSuccess("Typ wurde gespeichert!");
      this.$router.replace({
        name: "PortfolioTypeDetails",
        params: { id: this.type.id },
      });
    },
  },
  async created() {
    this.loading = true;
    this.type = await this.apiGet({ resource: "portfolio/type", id: this.id });
    this.divisions = await this.apiList({ resource: "common/division" });
    this.grades = await this.apiList({ resource: "common/grade" });
    this.semesters = await this.apiList({ resource: "common/semester" });
    this.subjects = await this.apiList({ resource: "common/subject" });
    this.loading = false;
  },
};
</script>
