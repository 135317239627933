<template>
  <v-row v-bind="$attrs">
    <v-col cols="4" class="text-right text--secondary">{{ title }} </v-col>
    <v-col align="left"><slot></slot> </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'RowItem',
  props: ['title'],
};
</script>